import React, { Children, createContext, useEffect } from 'react'
import { useState } from 'react';
import { ObtenerDatos } from '../helpers/ApiHelper';
import { obtenerTemplates } from '../helpers/TemplatesHelper';

 export const TemplatesContext = createContext();

 export const TemplatesProvider = (props) => {
    const [templateSeleccionado, setTemplateSeleccionado] = useState()
    const [templates, setTemplates] = useState();

    useEffect(() => {
        obtenerTemplates().then(response=>{
            setTemplates(response);
        })
    }, [])
    
    useEffect(() => {
        console.log('el state de templates cambio', templates)
    }, [templates])
    


    return(
        <TemplatesContext.Provider value={{
            templateSeleccionado, setTemplateSeleccionado, templates
        }}>
            {props.children}
        </TemplatesContext.Provider>
    )
}
      
      