import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import MuiListItem from '@mui/material/ListItem';
//import MuiListItem from "@material-ui/core/ListItem";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Person from '@mui/icons-material/Person';
import { Autocomplete, Avatar, makeStyles, TextField, withStyles } from '@mui/material';
import { useState } from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PublicIcon from '@mui/icons-material/Public';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { DashboardPage } from './DashboardPage';
import { ReportesPage } from './ReportesPage';
import { ClassNames } from '@emotion/react';
import { CiudadPage } from './CiudadPage';
import { CiudadanosPage } from './CiudadanosPage';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { ServiciosPage } from './ServiciosPage';
import { TramitesPage } from './TramitesPage';
import { LoginPage } from './LoginPage';
import { AuthContext } from '../context/AuthContext';
import { TemplatesContext, TemplatesProvider } from '../context/TemplatesContext';
import { Chat, MapSharp, People } from '@mui/icons-material';
import { SectoresPage } from './SectoresPage';
import { UsuariosPage } from './UsuariosPage';
import { ObtenerDependencias } from '../helpers/DependenciasHelper';
import imagotipo from '../assets/imagotipo.png'
import { TemplatesPage } from './TemplatesPage';
import { useEffect } from 'react';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);




export default function PrincipalPage() {
  const {usuario, dependenciaMostrar, setIdDependenciaMostrar, configuracion, logueado} = React.useContext(AuthContext);
  
  console.log('usuario logueado en principal page', usuario);
  
  const navigate = useNavigate();
  const [seleccion, setSeleccion] = useState('dashboard');
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('dashboard');
  const [dependencias, setDependencias] = useState()
  const [valorIdDependencia, setValorIdDependencia] = useState()
  
  const obtenerDependencias = () => {
    ObtenerDependencias(usuario?.Usuarios_IdUsuario).then(response => {
      console.log('dependencias obtenidas en principal page', response)
      let opciones = new Array()
      opciones.push({id:0, label:'Todas las dependencias'})
      response?.map(dependencia => {
        opciones.push({id:dependencia.IdDependencia, label:dependencia.Dependencia})
      })
      setDependencias(opciones);
    })
  } 
  React.useEffect(() => {
    console.log('configuracion cambio', configuracion)
  }, [configuracion])
  
  React.useEffect(() => {
    obtenerDependencias();
  }, [])

  useEffect(() => {
    if(logueado) {
      obtenerDependencias();
    }
  }, [usuario])
  
  

  const handleDrawerOpen = () => {
    if(open==true) {
        setOpen(false);
    } else {
        setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
if(configuracion?.fondo) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
            style={{backgroundColor:'white', color:'black'}}
        >
                      <img  
                src={'https://plataformadecide.com/imagenes/'+usuario?.idCliente+'/'+configuracion?.logo}
                style={{
                  visibility: open ? 'hidden':'visible',
                  width:'auto',
                  height:40,
                  marginRight:5,
                  
                 // width:150,
                //  height:150
                }}
              />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: '' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{
                display:'flex',
                flex:1,
                flexDirection:'row',
                justifyContent:'flex-end'
            }}
          >
            {
              usuario?.IdTipoUsuario==2 ? (
                <div
                  style={{
                    display:'flex',
                    marginRight:20,
                    justifyContent:'center',
                    minWidth:400
                  }}
                >
                </div>
              ):null
            }
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    marginRight:10
                }}
            >
                <NotificationsNoneIcon />
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column'
                }}
            >
                <div 
                    style={{
                        fontSize:14
                    }}
                >
                        {usuario.Usuarios_Nombre+' '+usuario.Usuarios_ApellidoPaterno}
                </div>
                <div 
                    style={{
                        opacity:0.4, 
                        fontSize:12,
                        display:'flex',
                        flexDirection:'row',
                        flex:1,
                        justifyContent:'flex-end'
                    }}
                >
                    {
                      usuario.Usuarios_IdTipoUsuario=='2' ? (
                        <Typography style={{fontSize:11}}>Admin</Typography>
                      )
                      : (
                        <Typography style={{fontSize:11}}>Usuario</Typography>

                      )

                    }
                </div>
            </div>
            <div
                style={{
                    marginLeft:10
                }}
            >
                <Avatar>
                    <Person />
                </Avatar>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <div
            style={{
                display:'flex',
                flex:1,
                backgroundColor:'white',
                justifyContent:'center'
            }}
          >
            <img  
                src={'https://plataformadecide.com/imagenes/'+usuario?.idCliente+'/'+configuracion?.logo}
                style={{
                  width:90,
                  height:'auto'
                }}
              />

          </div>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
        </DrawerHeader>
        {/* <Divider /> */}
        <List>
          {
            (usuario?.permisos).substr(0,1)==1 ? (
            <ListItem key='dashboard' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              onClick={(e) => {setSeleccion('dashboard'); navigate("/dashboard"); }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: seleccion==='dashboard' ? configuracion.fondo :'white' ,
                  color: seleccion==='dashboard' ? 'white':'black',
                  
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                    <GridViewIcon
                        style={{
                          color: seleccion==='dashboard' ? 'white':'black',
                        }}
                    /> 
                </ListItemIcon>
                <ListItemText primary='Dashboard' sx={{ opacity: open ? 1 : 0}} />
              </ListItemButton>
            </ListItem>
            ):null
            }
          {
            (usuario?.permisos).substr(1,1)==1 ? (

            <ListItem key='reportes' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              onClick={(e) => {setSeleccion('reportes');navigate("/reportes"); }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: seleccion==='reportes' ? configuracion.fondo :'white' ,
                  color: seleccion==='reportes' ? 'white':'black',
              }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
  
                  }}
                >
                    <AssignmentIcon  
                        style={{
                          color: seleccion==='reportes' ? 'white':'black',
                        }}
                    /> 
                </ListItemIcon>
                <ListItemText primary='Usuarios' sx={{ opacity: open ? 1 : 0}} />
              </ListItemButton>
            </ListItem>
            ):null
          }
         {
            (usuario?.permisos).substr(2,1)==1 ? (
            <ListItem key='ciudad' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              onClick={(e) => {setSeleccion('ciudad');navigate("/ciudad"); }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: seleccion==='ciudad' ? configuracion.fondo :'white' ,
                  color: seleccion==='ciudad' ? 'white':'black',
              }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
  
                  }}
                >
                    <PublicIcon  
                        style={{
                          color: seleccion==='ciudad' ? 'white':'black',
                        }}
                    /> 
                </ListItemIcon>
                <ListItemText primary='Ciudad' sx={{ opacity: open ? 1 : 0}} />
              </ListItemButton>
            </ListItem>
            ):null
          }      
         {
            (usuario?.permisos).substr(3,1)==1 ? (

            <ListItem key='ciudadanos' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              onClick={(e) => {setSeleccion('ciudadanos');navigate("/ciudadanos"); }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: seleccion==='ciudadanos' ? configuracion.fondo :'white' ,
                  color: seleccion==='ciudadanos' ? 'white':'black',
              }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
  
                  }}
                >
                    <PersonOutlineOutlinedIcon  
                        style={{
                          color: seleccion==='ciudadanos' ? 'white':'black',
                        }}
                    /> 
                </ListItemIcon>
                <ListItemText primary='Ciudadanos' sx={{ opacity: open ? 1 : 0}} />
              </ListItemButton>
            </ListItem>
            ):null
          }        
         {
            (usuario?.permisos).substr(4,1)==1 ? (

            <ListItem key='servicios' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              onClick={(e) => {setSeleccion('servicios');navigate("/servicios"); }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: seleccion==='servicios' ? configuracion.fondo :'white' ,
                  color: seleccion==='servicios' ? 'white':'black',
              }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
  
                  }}
                >
                    <EngineeringIcon  
                        style={{
                          color: seleccion==='servicios' ? 'white':'black',
                        }}
                    /> 
                </ListItemIcon>
                <ListItemText primary='Servicios' sx={{ opacity: open ? 1 : 0}} />
              </ListItemButton>
            </ListItem>
           ):null
          }         
         {
            (usuario?.permisos).substr(5,1)==1 ? (

            <ListItem key='tramites' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              onClick={(e) => {setSeleccion('tramites');navigate("/tramites"); }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: seleccion==='tramites' ? configuracion.fondo :'white' ,
                  color: seleccion==='tramites' ? 'white':'black',
              }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
  
                  }}
                >
                    <EngineeringIcon  
                        style={{
                          color: seleccion==='tramites' ? 'white':'black',
                        }}
                    /> 
                </ListItemIcon>
                <ListItemText primary='Tramites' sx={{ opacity: open ? 1 : 0}} />
              </ListItemButton>
            </ListItem>
           ):null
          }         
         {
            (usuario?.permisos).substr(6,1)==1 ? (            
            <ListItem key='sectores' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              onClick={(e) => {setSeleccion('sectores');navigate("/sectores"); }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: seleccion==='sectores' ? configuracion.fondo :'white' ,
                  color: seleccion==='sectores' ? 'white':'black',
              }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
  
                  }}
                >
                    <MapSharp  
                        style={{
                          color: seleccion==='sectores' ? 'white':'black',
                        }}
                    /> 
                </ListItemIcon>
                <ListItemText primary='sectores' sx={{ opacity: open ? 1 : 0}} />
              </ListItemButton>
            </ListItem>
           ):null
          }         
         {
            (usuario?.permisos).substr(7,1)==1 ? (            

            <ListItem key='usuarios' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              onClick={(e) => {setSeleccion('usuarios');navigate("/usuarios"); }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: seleccion==='usuarios' ? configuracion.fondo :'white' ,
                  color: seleccion==='usuarios' ? 'white':'black',
              }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
  
                  }}
                >
                    <People  
                        style={{
                          color: seleccion==='usuarios' ? 'white':'black',
                        }}
                    /> 
                </ListItemIcon>
                <ListItemText primary='Usuarios' sx={{ opacity: open ? 1 : 0}} />
              </ListItemButton>
            </ListItem>
           ):null
          }                     
         {
            (usuario?.permisos).substr(7,1)==1 ? (            

            <ListItem key='templates' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              onClick={(e) => {setSeleccion('templates');navigate("/templates"); }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: seleccion==='templates' ? configuracion.fondo :'white' ,
                  color: seleccion==='templates' ? 'white':'black',
              }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
  
                  }}
                >
                    <Chat  
                        style={{
                          color: seleccion==='templates' ? 'white':'black',
                        }}
                    /> 
                </ListItemIcon>
                <ListItemText primary='Templates' sx={{ opacity: open ? 1 : 0}} />
              </ListItemButton>
            </ListItem>
           ):null
          }                     
        </List>
      </Drawer>
      <Box style={{
        backgroundColor:'#FAFAFA',
        display:'flex',
        flexDirection:'column',
        flex:1,
        height:'100vh'

      }} component="main" sx={{ flexGrow: 1, justifyContent:'left' }}>
        <DrawerHeader />
      <Routes>
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/dashboard" element={<DashboardPage />} />
            <Route exact path="/reportes" element={<ReportesPage />} />
            <Route exact path="/ciudad" element={<CiudadPage />} />
            <Route exact path="/ciudadanos" element={<CiudadanosPage />} />
            <Route exact path="/servicios" element={<ServiciosPage />} />
            <Route exact path="/tramites" element={<TramitesPage />} />
            <Route exact path="/sectores" element={<SectoresPage />} />
            <Route exact path="/usuarios" element={<UsuariosPage />} />
            <Route exact path="/templates" element={<TemplatesProvider><TemplatesPage /></TemplatesProvider>} />
        </Routes> 
      </Box>
    </Box>
  );
    }else {
      return (
        <div>Cargando...</div>
      )
    }
}