import logo from './logo.svg';
import './App.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { SocketProvider } from './context/SocketContext';
import { useContext, useEffect } from 'react';
import { AuthContext } from './context/AuthContext';
import { Route, Routes } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import PrincipalPage from './pages/PrincipalPage';
import { DashboardPage } from './pages/DashboardPage';
import { ReportesPage } from './pages/ReportesPage';
import { CiudadPage } from './pages/CiudadPage';
import { CiudadanosPage } from './pages/CiudadanosPage';
import { ServiciosPage } from './pages/ServiciosPage';
import { TramitesPage } from './pages/TramitesPage';
import { SectoresPage } from './pages/SectoresPage';
import { UsuariosPage } from './pages/UsuariosPage';
import { ObtenerTecnicosDependencia } from './helpers/UsuariosHelper';
import { TemplatesPage } from './pages/TemplatesPage';


function  App()  {
  const {logueado, obtenerConfiguracion, usuario} = useContext(AuthContext);

  useEffect(() => {
    if(logueado) {
      console.log('obteniendo configuracion en appjs',usuario,logueado)
      obtenerConfiguracion(usuario?.Usuarios_IdUsuario);
    }
    console.log('Logueado', logueado);
  }, [])
  

  return (
    // <SocketProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Routes>
      {
        !logueado ? (
          <>
            <Route path="/" element={<LoginPage />} /> 
            <Route name="login" path="/" element={<LoginPage />} />
          </>
        )
        :
        (
          <>
          <Route name="home" path="/" element={<PrincipalPage />} >
            <Route exact path="/dashboard" element={<DashboardPage />} />
            <Route exact path="/reportes" element={<ReportesPage />} />
            <Route exact path="/ciudad" element={<CiudadPage />} />
            <Route exact path="/ciudadanos" element={<CiudadanosPage />} />
            <Route exact path="/servicios" element={<ServiciosPage />} />
            <Route exact path="/tramites" element={<TramitesPage />} />          
            <Route exact path="/sectores" element={<SectoresPage />} />          
            <Route exact path="/usuarios" element={<UsuariosPage />} />          
            <Route exact path="/templates" element={<TemplatesPage />} />          
          </Route>
          </>
        )
      }
    </Routes>

    </LocalizationProvider>
    // </SocketProvider>
  );
}

export default App;