import { ObtenerDatos } from "./ApiHelper"

export const obtenerTemplates = () => {
    return new Promise((resolve, reject) => {
        console.log(process.env.REACT_APP_API_URL)
        ObtenerDatos('/templates.php?funcion=obtenerTemplates').then(response=> {
            resolve(response)
        })
    })
}

export const guardarParametro = (datos) => {
    console.log('datos recibidos para guardar', datos)
    return new Promise((resolve, reject) => {
        console.log(process.env.REACT_APP_API_URL)
        const data = new FormData();
        data.append('datos', JSON.stringify(datos))
        ObtenerDatos('/templates.php?funcion=guardarTemplate', data).then(response=> {
            alert('Parametro guardado');
            resolve(response)
        })
    })
}
