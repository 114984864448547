import React, { useContext, useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { styled } from '@mui/material/styles';

import { IconButton, Tooltip, tooltipClasses  } from '@mui/material';
import { ReportesContext } from '../pages/ReportesPage';
import { AuthContext } from '../context/AuthContext';
import { Delete, Edit } from '@mui/icons-material';
import dayjs from 'dayjs';
import { EliminarReporte } from '../helpers/ReportesHelper';
import Swal from 'sweetalert2';
import Select from 'react-select';

export const TablaReportes = ({reportesTabla, idTipoAyuda, setIdTipoAyuda, idTipoIdea, setIdTipoIdea}) => {
    const [reportesTablaMostrar, setReportesTablaMostrar] = useState()
    const {modalReporte, setModalReporte, setReporteSeleccionado, modalEdicionReporte, setModalEdicionReporte, setTotalReportesVista, setModalAsignarTecnico, Reportes} = useContext(ReportesContext)
    const {usuario, idDependenciaMostrar, configuracion} = useContext(AuthContext)
    const [tiposAyuda, setTiposAyuda] = useState(
        [
            {value:0,label:'Todo'},
            {value:1,label:'Activista digital'},
            {value:2,label:'Con mis vecinos'},
            {value:3,label:'Apoyo en eventos'},
            {value:4,label:'Defendiendo la 4T'},
            {value:5,label:'En comité de defensa'},
        ]
    )
    const [tiposIdea, setTiposIdea] = useState([
        {value:0,label:'Todo'},
        {value:1,label:'Seguridad'},
        {value:2,label:'Salud'},
        {value:3,label:'Educación'},
        {value:4,label:'Infraestructura'},
        {value:5,label:'Otro'},
    ])

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip placement='right' {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 500,
          borderRadius:10,
          padding:0
        },
      });

      useEffect(() => {
        setTotalReportesVista(reportesTablaMostrar?.length)
      }, [reportesTablaMostrar])
      

      useEffect(() => {
        console.log('reportes tabla mostrar', reportesTablaMostrar)
        if(idDependenciaMostrar==0) {
            setReportesTablaMostrar(reportesTabla)
        } else {
            const reportesMostrar = reportesTabla?.filter(reporte => reporte.IdDependencia==idDependenciaMostrar);
            setReportesTablaMostrar(reportesMostrar)
        }
      }, [reportesTabla, idDependenciaMostrar])
      
      const eliminarReporte = (idReporte) => {
        
        Swal.fire({
          title: '¿Estás seguro(a)?',
          text: "Se eliminará la clase, esto no puede ser revertido",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar!'
        }).then((result) => {
          if (result.isConfirmed) {
            EliminarReporte(idReporte, usuario?.Usuarios_IdUsuario).then(response => {
                console.log('reporte eliminado');
                Reportes();
            
              if(response==1) {
                Swal.fire(
                  'Eliminado!',
                  'El reporte ha sido eliminado.',
                  'success'
                )
//                Reportes();
              } else {
                Swal.fire(
                  'Error!',
                  'El reporte no pudo ser eliminado!.',
                  'error'
                )
    
              }
            })
          }
        })
      }


     

  return (
    <div
        style={{
            display:'flex',
            flex:1
        }}
    >
        <TableContainer
            style={{
                display:'flex',
                flex:1,
                maxHeight:'70vh'
                
            }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {/* <TableCell>Prioridad</TableCell> */}
                        <TableCell>Foto</TableCell>
                        <TableCell>Notas</TableCell>
                        <TableCell>Folio</TableCell>
                        {/* <TableCell>Técnico</TableCell> */}
                        <TableCell>Ciudadano</TableCell>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Dirección</TableCell>
                        <TableCell>Categoria</TableCell>
                        {/* <TableCell>Estatus</TableCell> */}
                        <TableCell>Enviado</TableCell>
                        {/* <TableCell>Proceso</TableCell> */}
                        {/* <TableCell>Resuelto</TableCell> */}
                        {/* <TableCell>Sector</TableCell> */}
                        <TableCell>
                            {/* Tipo ayuda */}
                            <Select
                //    defaultValue={selectedOption}
                onChange={(e) => {
                    setIdTipoAyuda(e.value)
                }}
                fullWidth
                  //  unstyled
                    options={tiposAyuda}
                    placeholder="Tipo de Ayuda"
                    menuPortalTarget={document.body} 
                    variant="standard"
                 //   value={datosEnlace?.idModuloDestino || null}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    // style={{
                    //     marginTop:10, width:'100%',
                    //     display:'flex',
                    //     flex:1
                    // }}
                    InputLabelProps={{
                        style:{
                            fontSize:12,
                            width:'100%',
                            padding:0
                        }
                    }}
                    InputProps={{
                        style:{
                            fontSize:12,
                            paddingLeft:0,
                            marginLeft:0
                        }
                    }}
                    styles={{ 
                        menuPortal: base => ({ 
                            ...base, 
                            zIndex: 9999, 
                            fontSize:12,
                            marginTop:1,
                            padding:0
                        }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderBottomColor: state.isFocused ? 'grey' : 'green',
                        borderTopColor:'transparent',
                        borderLeftColor:'transparent',
                        borderRightColor:'transparent',
                        fontSize:12,
                        minWidth:150,
                        maxHeight:35,
                        width:'100%',
                        minWidth:150,
                        paddingLeft:0,
                        padding:0
                    }),
                }}
                    style={{
                        display:'flex',
                        flex:1,
                        width:'300',
                        maxHeight:35,
                        menuPortal: base => ({ ...base, zIndex: 999999, fontSize:12, width:300, minWidth:300, padding:0 }),

                    }}
                />
                        </TableCell>
                        <TableCell>
                        <Select
                //    defaultValue={selectedOption}
                onChange={(e) => {
                    setIdTipoIdea(e.value)
                }}
                fullWidth
                  //  unstyled
                    options={tiposIdea}
                    placeholder="Tipo de Idea"
                    menuPortalTarget={document.body} 
                    variant="standard"
                 //   value={datosEnlace?.idModuloDestino || null}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    // style={{
                    //     marginTop:10, width:'100%',
                    //     display:'flex',
                    //     flex:1
                    // }}
                    InputLabelProps={{
                        style:{
                            fontSize:12,
                            width:'100%',
                            padding:0
                        }
                    }}
                    InputProps={{
                        style:{
                            fontSize:12,
                            paddingLeft:0,
                            marginLeft:0
                        }
                    }}
                    styles={{ 
                        menuPortal: base => ({ 
                            ...base, 
                            zIndex: 9999, 
                            fontSize:12,
                            marginTop:1,
                            padding:0
                        }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderBottomColor: state.isFocused ? 'grey' : 'green',
                        borderTopColor:'transparent',
                        borderLeftColor:'transparent',
                        borderRightColor:'transparent',
                        fontSize:12,
                        minWidth:150,
                        maxHeight:35,
                        width:'100%',
                        minWidth:150,
                        paddingLeft:0,
                        padding:0
                    }),
                }}
                    style={{
                        display:'flex',
                        flex:1,
                        width:'300',
                        maxHeight:35,
                        menuPortal: base => ({ ...base, zIndex: 999999, fontSize:12, width:300, minWidth:300, padding:0 }),

                    }}
                />                            
                        </TableCell>
                        <TableCell>Rec. Información</TableCell>
                        <TableCell>Nombre/Municipio</TableCell>
                        {
                            (usuario?.permisos).substr(9,1)==1 ? (     
                                <TableCell></TableCell>
                                ):null
                            }
                        {
                             usuario?.Usuarios_IdTipoUsuario==2 ? (     
                            <TableCell></TableCell>
                             ):null
                        }
                    </TableRow>
                </TableHead>
                <TableBody
                    style={{
                        fontSize:'.7rem'
                    }}
                >
                {
                    reportesTablaMostrar?.map(reporte => (
                       
                        <TableRow
                            style={{
                                fontSize:11
                            }}
                        >
                            {/* <TableCell style={{
                                fontSize:12
                            }}>
                                Nivel {reporte?.IdPrioridad ? reporte.IdPrioridad:1}
                            </TableCell> */}
                            <TableCell
                                onClick={() => {setReporteSeleccionado(reporte); setModalReporte(true)}}
                                style={{
                                    fontSize:12
                                }}
                            >
                                <CustomWidthTooltip 

                                    componentsProps={{
                                        tooltip: {
                                        sx: {
                                            bgcolor: configuracion.fondo,
                                            '& .MuiTooltip-arrow': {
                                            color: 'common.black',
                                            maxWidth:600,
                                            
                                            // width:300
                                            },
                                        },
                                        },
                                    }}                                
                                    style={{
                                        backgroundColor:'white',
                                       
                                    }}
                                    title={
                                        <>
                                        <div
                                            style={{
                                                backgroundColor:'transparent',
                                                display:'flex',
                                                flexDirection:'row'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width:500,
                                                    height:600,
                                                    flex:40,
//                                                    alignItems:'flex',
                                                    display:'flex',
                                                    flexDirection:'column',
                                                    justifyContent:'space-around'
                                                }}
                                            >
                                                <div>
                                                <img onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="";
  }} style={{width:'auto', height:200, borderRadius:10, position:'relative', margin:30}} src={'http://plataformadecide.com/imagenes/'+usuario?.idCliente+'/Reporte-'+reporte.IdReporte.padStart(6,"0")+'.jpg'} />
                                                </div>
                                                <div>
                                                {reporte?.Resuelto ? (
                                                <img onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src="";
                                                  }} style={{width:'auto', height:200, borderRadius:10, position:'relative', margin:30}} src={'http://plataformadecide.com/imagenes/'+usuario?.idCliente+'/Tecnico-'+reporte.IdReporte.padStart(6,"0")+'.jpg'} />
                                                
                                                ):null
                                            }
                                            </div>
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor:configuracion.fondo,
                                                    width:'auto'
                                                }}
                                            >
                                            </div>
                                            <div
                                                style={{
                                                    display:'flex',
                                                    flexDirection:'column',
                                                    backgroundColor:'white',
                                                    flex:70,
                                                    alignItems:'center',
                                                    
                                                }}
                                            >
                                            <img  
                                                src={process.env.REACT_APP_IMAGENES+'/'+configuracion?.logo } 
                                                style={{
                                                width:120,
                                                height:'auto',
                                                marginTop:30
                                                }}
                                            />
                                            </div>
                                            </div>                                                
                                        </>
                                    }>
                                    <PhotoOutlinedIcon />
                                </CustomWidthTooltip>
                            </TableCell>
                            <TableCell
                                 style={{
                                    fontSize:12
                                }}
                                onClick={() => setModalReporte(true)}
                            >
                                <>
                                <CustomWidthTooltip                      
                                componentsProps={{
                                        tooltip: {
                                        sx: {
                                            bgcolor: '#642644',
                                            '& .MuiTooltip-arrow': {
                                            color: 'common.black',
                                            maxWidth:600,
                                            
                                            // width:300
                                            },
                                        },
                                        },
                                    }}                                
                                    style={{
                                        backgroundColor:'white',
                                       
                                    }}
                                    title={
                                        <div
                                            style={{
                                                maxWidth:300,
                                                padding:30
                                            }}
                                        >
                                           {reporte.Descripcion}
                                        </div>
                                    } 
                                >
                                <DescriptionOutlinedIcon />
                                </CustomWidthTooltip>
                                </>
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                #{reporte.IdReporte}
                            </TableCell>
                            {/* <TableCell
                                onClick={() => {console.log('click en cambiar tecnico', reporte); setReporteSeleccionado(reporte); setModalAsignarTecnico(true)}}
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.tecnico}
                            </TableCell> */}
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte?.IdPlataforma==3 ? reporte?.Usuarios_Nombre+' '+reporte?.Usuarios_ApellidoPaterno : reporte.ProfileName}
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte?.Telefono}
                            </TableCell>
                            {/* <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.Plataforma}
                            </TableCell> */}
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.DireccionReporte}
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.TipoReporte}
                            </TableCell>
                            {/* <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.EstatusReporte}
                            </TableCell> */}
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.Enviado}
                            </TableCell>
                            {/* <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {dayjs(dayjs()).diff(dayjs(reporte.Fecha),'day')} día(s)
                            </TableCell> */}
                            {/* <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte.Resuelto}
                            </TableCell> */}
                            {/* <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte?.Sector}
                            </TableCell> */}
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte?.tipoAyuda}
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte?.tipoIdea}
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte?.recibirInformacion ==1 ? 'Sí':'No'}
                            </TableCell>
                            <TableCell
                             style={{
                                fontSize:12
                            }}
                            >
                                {reporte?.nombreMunicipio}
                            </TableCell>
                            {
                            (usuario?.permisos).substr(9,1)==1 ? (     
                            <>
                            {
                                reporte.IdEstatus=="2" ? (
                            <TableCell>
                                <IconButton
                                    onClick={() => {setReporteSeleccionado(reporte); setModalEdicionReporte(true)}}
                                >
                                    <Edit size="small" style={{fontSize:14, color:'green'}} />
                                </IconButton>
                            </TableCell> 
                            ):(
                                <TableCell>
                                <IconButton
                                  disabled
                                >
                                    <Edit size="small" style={{fontSize:14, color:'gray'}} />
                                </IconButton>
                            </TableCell> 

                            )
                            }
                            </>
                            ):null
                        }
                        {
                            usuario?.IdTipoUsuario==2 ? (
                                <TableCell>
                                <IconButton
                                  onClick={() => eliminarReporte(reporte.IdReporte)}
                                >
                                    <Delete size="small" style={{fontSize:14, color:'red'}} />
                                </IconButton>
                            </TableCell> 
                            ):null
                        }   
                        </TableRow>
                        
                    ))
                }
                </TableBody>
            </Table>
        </TableContainer>
    </div>
  )
}
