import React, { createContext, useContext, useEffect, useState } from 'react'
import { ContadorBaches } from '../components/ContadorBaches'
import { ContadorCiudadanos } from '../components/ContadorCiudadanos'
import { ContadorLuminarias } from '../components/ContadorLuminarias'
import { ContadorReportes } from '../components/ContadorReportes'
import { ContadorReportesComponent } from '../components/ContadorReportesComponent'
import { GraficaBaches } from '../components/GraficaBaches'
import { GraficaColonias } from '../components/GraficaColonias'
import { GraficaLuminaria } from '../components/GraficaLuminaria'
import { GraficaServicioComponent } from '../components/GraficaServicioComponent'
import { GraficaTotal } from '../components/GraficaTotal'
import { TablaResumenReportes } from '../components/TablaResumenReportes'
import { AuthContext } from '../context/AuthContext'
import { ContadorSuVida } from '../components/ContadorSuVida'
import { ContadorBuenasIdeas } from '../components/ContadorBuenasIdeas'
import { ContadorQuieroSumar } from '../components/ContadorQuieroSumar'

export const DashboardContext = createContext();
export const DashboardPage = () => {
  
  const {usuario, idDependenciaMostrar} = useContext(AuthContext);
  const [datosDashboard, setdatosDashboard] = useState([]);
  const [datosBot, setDatosBot] = useState();
  const [tiposReporte, setTiposReporte] = useState([]);

  useEffect(() => {
    obtenerTiposReporte();
    obtenerDatosDashboard();
    obtenerDatosBot();
  }, [])
  
  useEffect(() => {
    obtenerTiposReporte();
    obtenerDatosDashboard();
  }, [idDependenciaMostrar])
  
  const obtenerDatosDashboard = () => {
    const data = new FormData();
    data.append('idUsuario', usuario?.Usuarios_IdUsuario)
    fetch(process.env.REACT_APP_API_URL+'/api.reportes.php?funcion=datosDashboard&idUsuario='+usuario.Usuarios_IdUsuario,{
      body:data,
      method:'POST'
    })
    .then(response => response.json())
    .then(datos => {
      setdatosDashboard(datos);
      console.log(datos);
    })
    .catch(error => {
      console.log('Ocurrio un error', error)
    })
  }

  const obtenerDatosBot = () => {
    const data = new FormData();
    data.append('idUsuario', usuario?.Usuarios_IdUsuario)
    fetch(process.env.REACT_APP_API_URL+'/api.reportes.php?funcion=datosBot&idUsuario='+usuario.Usuarios_IdUsuario,{
      body:data,
      method:'POST'
    })
    .then(response => response.json())
    .then(datos => {
      setDatosBot(datos);
      console.log('datos de bot',datos);
    })
    .catch(error => {
      console.log('Ocurrio un error', error)
    })
  }


  const obtenerTiposReporte = () => {
    const data = new FormData();
    data.append('idUsuario', usuario?.Usuarios_IdUsuario)
    fetch(process.env.REACT_APP_API_URL+'/api.reportes.php?funcion=tiposReporte&idUsuario='+usuario.Usuarios_IdUsuario+'&idDependenciaMostrar='+idDependenciaMostrar, {
      body:data,
      method:'POST'
    })
    .then(response => response.json())
    .then(datos => {
        console.log('tipos de reporte', datos);
        if(datos?.length) {
          setTiposReporte(datos);
        } else {
          setTiposReporte();
        }
    })
    .catch(error => {
        console.error('Ocurrio un error', error);
    })
  }

  
  

  return (
    <DashboardContext.Provider value={{datosDashboard}}>

    <div
        style={{
            color:'black',
            display:'flex',
            flexDirection:'column',
            flex:1,
            backgroundColor:'#FAFAFA',
            margin:5,
            borderRadius:10,
            marginBottom:20
        }}
    >
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          flex:1,
          backgroundColor:'transparent',
          maxHeight:'25vh',
          padding:20,
          justifyContent:'space-between'
        }}
      >
        {
          usuario.IdTipoUsuario==2 ? (
        <>
          <ContadorReportes />
        </>
          ):null
        }
        <ContadorSuVida datosBot={datosBot} />
        <ContadorBuenasIdeas datosBot={datosBot} />
        <ContadorQuieroSumar datosBot={datosBot} />
             {
        tiposReporte?.map(tipoReporte => (
          <div
          style={{
            backgroundColor:'transparent',
            display:'flex',
            flexDirection:'row',
            flex:40,
            minWidth:300
          }}
        ><ContadorReportesComponent tipoReporte={tipoReporte} /></div>

        ))
      }
          {/* <ContadorReportes />
          <ContadorLuminarias />
          <ContadorBaches /> */}
          {/* <ContadorCiudadanos /> */}
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          //flex:1,
          backgroundColor:'transparent',
     //     maxHeight:'20vh',
          padding:20,
          justifyContent:'space-between',
          maxWidth:'90vw',
          overflowX:'auto',
          overflowY:'hidden'
        }}
      >
      {
        tiposReporte?.map(tipoReporte => (
          <div
          style={{
            backgroundColor:'transparent',
            display:'flex',
            flexDirection:'row',
            flex:33
          }}
        ><GraficaServicioComponent tipoReporte={tipoReporte} /></div>

        ))
      }
        {/* <div
          style={{
            backgroundColor:'transparent',
            display:'flex',
            flexDirection:'row',
            flex:33,
            marginLeft:20
          }}
        ><GraficaLuminaria /></div> */}
        {/* <div
          style={{
            backgroundColor:'transparent',
            display:'flex',
            flexDirection:'row',
            flex:33,
            marginLeft:20

          }}
        ><GraficaTotal /></div> */}
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          flex:1,
          backgroundColor:'transparent',
          maxHeight:'60vh',
          padding:20,
          justifyContent:'space-between',
          marginBottom:50,
          maxWidth:1300
        }}
      >
        {/* <div
          style={{
            display:'flex',
            flexDirection:'column',
            flex:1,
            minWidth:'45vh',
            maxWidth:'50%',
            height:'100%',
            //maxWith:400,
            
            justifyContent:'space-between',
            backgroundColor:'white',
            borderRadius:10
          }}
        >
          <TablaResumenReportes />
        </div> */}
        {/* <div
          style={{
            display:'flex',
            flexDirection:'column',
            flex:50, marginLeft:10,
            backgroundColor:'white',
            borderRadius:10,
            maxWidth:'50%',
            padding:20,
            minHeight:'40vh', 
          }}
        >
        
          <GraficaColonias />
        </div> */}
      </div>
    </div>
    </ DashboardContext.Provider >
  )
}
