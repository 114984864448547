import { ImageSearch } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, Dialog, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { AuthContext } from '../context/AuthContext';
import { CerrarReporte } from '../helpers/ReportesHelper';
import { ReportesContext } from '../pages/ReportesPage'

export const ModalEdicionReporte = () => {
    const {modalEdicionReporte, setModalEdicionReporte, reporteSeleccionado} = useContext(ReportesContext);
    const {usuario, configuracion} = useContext(AuthContext);
    const [comentariosCierre, setComentariosCierre] = useState();
    const uploadInputRef = useRef(null);
    const imgMilitante = useRef(null);
    const [file, setFile] = useState()

    const SeleccionImagen = (event) => {
        setFile({file:event.target.files[0]});
        console.log('selecciono imagen escuela',event);
        var reader = new FileReader();
        reader.onload = function(){
            imgMilitante.current.src = reader.result;
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    console.log('reporte a editar', reporteSeleccionado)

    const handleClose = () => {
        setModalEdicionReporte(false)
    }

    const handleSubmit =  () => {
//        event.preventDefault();
        const data = new FormData();
        data.append('evidenciaTecnico', uploadInputRef.current.files[0])
        data.append('comentariosTecnico', comentariosCierre);
        data.append('idReporte', reporteSeleccionado?.IdReporte);
        data.append('idUsuario', usuario?.Usuarios_IdUsuario);

         CerrarReporte(data).then(respuesta => {
            console.log('respuesta de guardado en modal', respuesta);
            if(respuesta!=1) {

            } else{
                handleClose();

            }
        })
    }

  return (
    <Dialog maxWidth='xl' open={modalEdicionReporte} onClose={handleClose} >
        <DialogTitle>
            Reporte
        </DialogTitle>
        <Divider />
        <DialogContent>
        <div
            style={{
                display:'flex',
                flexDirection:'row',
            }}
        >
            <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="";
            }} style={{width:'auto', height:'45vh',maxWidth:'60%', borderRadius:10, position:'relative', margin:30}} src={'http://codigobyte.com.mx/pn/imagenes/Reporte-'+reporteSeleccionado?.IdReporte.padStart(6,"0")+'.jpg'} />
            <div
                style={{
                    display:'flex',
                    flexDirection:'column'
                }}
            >
            <img style={{width:'auto', height:'45vh',maxWidth:'60%', borderRadius:10, position:'relative', margin:30}}  ref={imgMilitante} src=''/>
            <Button
                style={{
                  textTransform:'unset',
                  backgroundColor:configuracion.fondoBotones,
                  borderRadius:10,
                  maxWidth:200
                }}
                variant="contained"
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >Adjuntar Evidencia<ImageSearch />
              </Button>
            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpg,.png' type="file" />
            </div>
            <Card
                style={{margin:20,
                    backgroundColor:'white'
                }}
            >
                <CardContent>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column'
                        }}
                    >
                        <Typography style={{fontSize:16, fontWeight:'600'}}>
                            Folio #{reporteSeleccionado?.IdReporte} - {reporteSeleccionado?.Enviado}
                        </Typography>
                        <Typography style={{fontSize:16, fontWeight:'600'}}>
                            {reporteSeleccionado?.Dependencia}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            {reporteSeleccionado?.TipoReporte}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            {reporteSeleccionado?.DireccionReporte}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            {reporteSeleccionado?.ProfileName  ? reporteSeleccionado?.ProfileName : reporteSeleccionado?.Usuarios_Nombre ? reporteSeleccionado?.Usuarios_Nombre+' '+reporteSeleccionado?.Usuarios_ApellidoPaterno:null}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            {reporteSeleccionado?.Telefono  ? reporteSeleccionado?.Telefono : null}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            Plataforma - {reporteSeleccionado?.Plataforma}
                        </Typography>
                        <Divider />
                        <Typography style={{fontSize:14, fontWeight:'400', marginTop:20}}>
                            {reporteSeleccionado?.Descripcion}
                        </Typography>
                        <Divider />
                        <Typography style={{fontSize:16, fontWeight:'600', marginTop:20}}>
                            Cierre de Reporte
                        </Typography>
                        
                        <TextField
                            label="Comentarios"
                            onChange={(newValue) => setComentariosCierre(newValue.target.value)}
                            style={{
                                marginTop:10,
                                borderRadius:10
                            }}
                            multiline
                            rows={2}
                            inputProps={{ maxLength: 100 }}
                        
                        />
                    </div>
                </CardContent>
                <CardActions
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'flex-end'
                    }}
                >
                    <Button>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => { handleSubmit()}}
                    >
                        Guardar
                    </Button>
                </CardActions>
            </Card>
        </div>
        </DialogContent>
    </Dialog>
  )
}
