export const CerrarReporte = async(data) => {
    return new Promise((resolve, reject) => {

       // data.append('orden','TipoInstalacion asc')
         fetch(process.env.REACT_APP_API_URL+'/api.reportes.php?funcion=servicioRealizado', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

export const EliminarReporte = async(idReporte, idUsuario) => {
    return new Promise((resolve, reject) => {

       // data.append('orden','TipoInstalacion asc')
       const data = new FormData();
       data.append('idReporte', idReporte)
       data.append('idUsuario', idUsuario)
         fetch(process.env.REACT_APP_API_URL+'/api.reportes.php?funcion=eliminarReporte', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

