import React, { useContext, useEffect, useState } from 'react'
import { TemplatesContext } from '../context/TemplatesContext'
import { Button, TextField } from '@mui/material'
import { guardarParametro } from '../helpers/TemplatesHelper'

export const TemplateParametros = () => {
    const {templateSeleccionado} = useContext(TemplatesContext)
    const [parametroSeleccionado, setParametroSeleccionado] = useState()

    useEffect(() => {
        console.log('parametro seleccioado cambio', parametroSeleccionado)
    }, [parametroSeleccionado])
    
    useEffect(() => {
        console.log('template seleccionado', templateSeleccionado)
    }, [templateSeleccionado])
    


  return (
    <div
    style={{
        display:'flex',
        flexDirection:'column',
        margin:20,
       

    }}
>
    {
        templateSeleccionado?.parametros?.map((parametro, index) => (
            <div
            style={{
                backgroundColor:'white',
                padding:5,
                shadowColor: 'blue',
                elevation: 5,  
                whiteSpace:'pre-wrap',
                boxShadow:'1 1 10 1 gray',
                borderRadius:10,
                margin:5,

            }}
            >
                <div
                    style={{
                        fontSize:11,
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center'
                    }}
                >
                    <div style={{display:'flex', flex:5}}>
                         {'{{'+parametro?.numeroParametro+'}}'}
                    </div>
                    <div style={{display:'flex', flex:85}}>
                        <TextField 
                            size='small'
                            onFocus={() => {setParametroSeleccionado(parametro)}}
                            onChange={(e) => {parametro.texto=e.target.value; setParametroSeleccionado({...parametroSeleccionado, 'texto':e.target.value})}}
                            fullWidth
                            defaultValue={parametro?.texto}
                            value={parametro?.texto}
                            InputProps={{
                                style:{
                                    fontSize:11
                                }
                            }}
                        />
                    </div>
                    <Button

                        onClick={() => {guardarParametro(parametroSeleccionado)}}
                        variant='contained'
                        size='small'
                        style={{
                            margin:5,
                            flex:10
                        }}
                    >
                        Guardar
                    </Button>
                </div>
            </div>

        ))
    }
    </div>
)
}
