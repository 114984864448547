import React, { useContext } from 'react'
import { TemplatesContext, TemplatesProvider } from '../context/TemplatesContext'
import { TablaTemplates } from '../components/TablaTemplates';
import { TemplateDetalle } from '../components/TemplateDetalle';
import { TemplateParametros } from '../components/TemplateParametros';

export const TemplatesPage = () => {
    const {templates} = useContext(TemplatesContext);

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            backgroundColor:'transparent'
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50, 
                backgroundColor:'transparent'
            }}
        >
            <TablaTemplates />
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50, 
                backgroundColor:'transparent'
            }}
        >
            <TemplateDetalle />
            <TemplateParametros />
        </div>
    </div>
  )
}
