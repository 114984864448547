export const GuardarUsuario = async(data) => {
    return new Promise((resolve, reject) => {

        //const data = new FormData();
       // data.append('orden','TipoInstalacion asc')
         fetch(process.env.REACT_APP_API_URL+'/usuarios.php?funcion=guardarUsuario', {
            method:'POST',
            body:JSON.stringify(data)
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

export const ObtenerTecnicosDependencia = async(data) => {
    return new Promise((resolve, reject) => {

        //const data = new FormData();
       // data.append('orden','TipoInstalacion asc')
         fetch(process.env.REACT_APP_API_URL+'/usuarios.php?funcion=tecnicosDependencia', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}
