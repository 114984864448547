import React, { useContext, useEffect } from 'react'
import { TemplatesContext } from '../context/TemplatesContext'
import { Divider, Typography, makeStyles } from '@mui/material'
import { AuthContext } from '../context/AuthContext';
export const TablaTemplates = () => {
    const {usuario, dependenciaMostrar, setIdDependenciaMostrar, configuracion} = React.useContext(AuthContext);
    const {templates, templateSeleccionado, setTemplateSeleccionado} = useContext(TemplatesContext)

    const styles = {
        encabezado: {
            fontSize:12,
            fontWeight:'600',
            backgroundColor:configuracion.fondoBotones,
            color:'white',
            padding:10
        },
        celda :{
            fontSize:11,
            fontWeight:'400',
            backgroundColor:'transparent',
            color:'black',
            paddingLeft:10,
            paddingBottom:5,
            paddingTop:5

            

        }
    }

    useEffect(() => {
        console.log('templates recibidos', templates)
    }, [])
    
    
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            margin:20
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                flex:1
            }}
        >
            <Typography style={{...styles.encabezado, flex:5}}>id</Typography>
            <Typography style={{...styles.encabezado, flex:70}}>Descripción</Typography>
            <Typography style={{...styles.encabezado, flex:30}}>sid</Typography>
        </div>
        {
            templates?.map((template, index) => (
                <>
                    <div
                        key={index}
                        onClick={() => {setTemplateSeleccionado(template)}}
                        style={{
                            backgroundColor:templateSeleccionado?.IdRespuesta==template?.IdRespuesta ? configuracion.fondoBotones : 'transparent',
                            display:'flex',
                            flex:1,
                            flexDirection:'row',
                        }}

                    >
                        <div style={{
                                ...styles.celda,
                                flex:5,
                            }}>
                                {template?.IdRespuesta}
                        </div>
                        <div style={{
                                ...styles.celda,
                                flex:70,
                            }}>
                                {template?.descripcion}
                        </div>
                        <div style={{
                                ...styles.celda,
                                flex:30,
                            }}>
                                {template?.sContentId}
                        </div>

                    </div>
                    <Divider />
                </>
            ))
        }
    </div>
  )
}

