import React, { createContext, useContext, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react';
import { AuthContext } from '../context/AuthContext';
import { TablaReportes } from '../components/TablaReportes';
import { ReportesContext, ReportesPage } from './ReportesPage';
import { Button, Divider, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModalReporte1 } from '../components/ModalReporte1';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    borderRadius:10,
    boxShadow: 24,
    p: 4,
  };
const defaultProps = {
    center: {
      lat: 28.700772688116892,
      lng: -106.53524198508615
    },
    zoom: 7
  };
  
export const CiudadContext = createContext();

export const CiudadPage = () => {
  const {idDependenciaMostrar, usuario} = useContext(AuthContext)
    const [layerReportes, setLayerReportes] = useState();
    const [map, setMap] = useState();
    const [maps, setMaps] = useState();
    const [isMapsLoaded, setIsMapsLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [open, setOpen] = useState(false)
    const [datosReporte, setdatosReporte] = useState();
    const [tecnicos, setTecnicos] = useState();
    const [idTecnico, setIdTecnico] = useState();
    const [modalReporte, setModalReporte] = useState(false)
    const [reporteSeleccionado, setReporteSeleccionado] = useState()
    const handleClose = () => {
       // onClose(selectedValue);
       setOpen(false)
      };
       
      const handleClickLayer = (event) => {
        console.log(event);
        const data = new FormData();
        data.append('idUsuario', usuario?.Usuarios_IdUsuario)
        fetch(process.env.REACT_APP_API_URL+'/api.reportes.php?funcion=datosReporte&idReporte='+event.featureData.name,{
          method:'POST',
          body:data
        })
        .then(response => response.json())
        .then(datos => {
            setReporteSeleccionado(datos[0])
            setModalReporte(true)
//            const idReporte = event.featureData.name;
 //           const rutaImagen='https://quejas.juarez.gob.mx/imagenes/Reporte-'+idReporte.padStart(6,"0")+'.jpg';
  //          console.log('ruta imagen', rutaImagen);
   //         datos[0].rutaImagen = rutaImagen;
    //        setdatosReporte(datos[0]);
     //       setIdTecnico(datos[0].IdTecnicoAsignado)
            
          console.log('datos 0',datos[0]);
        })
        .catch(error => {
          console.log('Ocurrio un error', error)
        })        
     //   setOpen(true);

      }

      const asignarTecnico = () => {
        const data = new FormData();
        data.append('idTecnico', idTecnico);
        data.append('idReporte', datosReporte?.IdReporte)
        fetch(process.env.REACT_APP_API_URL+'/api.reportes.php?funcion=asignarTecnico',{
          method:'POST',
          body:data
        })
        .then(response => response.json())
        .then(datos => {
            
          console.log('datos de asignacion', datos);
        })
        .catch(error => {
          console.log('Ocurrio un error', error)
        })        
      }

      const obtenerTecnicos = () => {
        fetch(process.env.REACT_APP_API_URL+'/usuarios.php?funcion=tecnicos')
        .then(response => response.json())
        .then(datos => {
          if(datos.length) {
            setTecnicos(datos);
          }
        })
        .catch(error => {
          console.log('ocurrio un error')
        })
      }

      useEffect(() => {
        obtenerTecnicos();
      }, [])
      

    const loadMap = (map, maps) => {
        setMap(map);
        setMaps(maps);
        const d = new Date();
        const seconds = Math.round(d.getTime() / 1000);
        console.log('ruta apertura mapa'+process.env.REACT_APP_API_URL+'/genera.capa.reportes.php?v='+seconds+'&idDependencia='+idDependenciaMostrar)
        const kmlLayerReportes = new maps.KmlLayer({
            url: process.env.REACT_APP_API_URL+'/genera.capa.reportes.php?v='+seconds+'&idDependencia='+idDependenciaMostrar+'&idUsuario='+usuario?.Usuarios_IdUsuario,
            suppressInfoWindows: true,
            draggable: true,
            zIndex:2000,
            map:map
        });
        console.log(kmlLayerReportes)
        setLayerReportes(kmlLayerReportes);
        kmlLayerReportes.addListener('click', function(event) {
         //   setMostrarDatosCapa('simpatizante');
         //   setIdSimpatizanteSeleccionado(event);
            handleClickLayer(event);
            console.log(event);
        })        
        
        setIsLoaded(true);


        
        setIsMapsLoaded(true);
      };
    
      useEffect(() => {
        console.log('cambio dependencia a mostrar en mapa', idDependenciaMostrar)
        const d = new Date();
        const seconds = Math.round(d.getTime() / 1000);
        layerReportes?.setMap(null)
        if(layerReportes?.url) {
        layerReportes.url=process.env.REACT_APP_API_URL+'/genera.capa.reportes.php?v='+seconds+'&idDependencia='+idDependenciaMostrar
        layerReportes?.setMap(map)
        }
      }, [idDependenciaMostrar])
      

  return (
    <CiudadContext.Provider value={{modalReporte, setModalReporte, reporteSeleccionado, setReporteSeleccionado}}>
    <>
    <div 
        style={{ 
            display:'flex',
            flexDirection:'column',
            flex:1,
            margin:30,
             borderRadius:20,
             overflow:'scroll'
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:70
            }}

        >
    <GoogleMapReact 
       
       bootstrapURLKeys={{ key: "AIzaSyBW_XR1RMYZ6NZlqdamgkpq0YqclLcYeOk", }}
       
       //ref={myRef}
       defaultCenter={defaultProps.center}
       defaultZoom={defaultProps.zoom}
       draggable={true}
       //year={year.value}
       //seccionseleccionada = {seccionseleccionada}
       onDragend= {(evt) => { }}
       yesIWantToUseGoogleMapApiInternals
       onGoogleApiLoaded={({ map, maps }) => {
           //mapRef.current = map;
           loadMap(map, maps);
           const d = new Date();
           const seconds = Math.round(d.getTime() / 1000);
           return(<b>este es un objeto dentro de una funcion</b>)
           
           
        }}
        onChange={({map, maps}) => {
            //console.log(year.year.value)
            
            //loadMap1(map,maps,year.year.value);
        }}
        >

    </GoogleMapReact>
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50, 
                overflowY:'scroll'
            }}
        >
            <ReportesPage />
        </div>
  </div>
  <ModalReporte1 />
  <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box style={{width:600}} sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      {datosReporte?.ProfileName}
    </Typography>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between'
        }}
    >
        <Typography id="modal-modal-title" style={{fontSize:13, fontWeight:'bold'}}>
        {datosReporte?.Telefono}
        </Typography>
        <Typography id="modal-modal-title" style={{fontSize:13, fontWeight:'bold'}}>
        Folio {datosReporte?.IdReporte}
        </Typography>
        

    </div>
    <Divider />
    <Typography id="modal-modal-title" style={{fontSize:13}}>
      Tipo de reporte: {datosReporte?.TipoReporte}
    </Typography>
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        alignItems:'center'

      }}
    >
      <Typography style={{marginRight:10}}>Técnico asignado:  </Typography>
    <select
      onChange={(e) => {console.log(e); setIdTecnico(e.target.value)}}
      style={{
        height:30
      }}
    >
      <option value='0'>No asignado</option>
      {
        tecnicos?.map(tecnico => (
          <option selected={tecnico.Usuarios_IdUsuario==idTecnico ? 'selected':''} value={tecnico.Usuarios_IdUsuario}>{tecnico.Usuarios_Nombre+' '+tecnico.Usuarios_ApellidoPaterno}</option>
        ))
      }
    </select>
    <Button
      onClick={() => {asignarTecnico()}}
      variant="contained"
      style={{
        textTransform:'unset',
        marginLeft:10
      }}
    >
      Asignar Técnico
    </Button>
    </div>
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        flex:1,
        justifyContent:'center'
      }}
    >
      <img style={{width:'200', maxWidth:'200', height:400, marginTop:40}} src={datosReporte?.rutaImagen} />

    </div>
  </Box>
</Modal>
</>
</CiudadContext.Provider>
  )
}
