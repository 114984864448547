import { Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import FlechaArriba from '@mui/icons-material/ArrowUpward';
import { DashboardContext } from '../pages/DashboardPage';
import DayJs from 'dayjs';
import 'dayjs/locale/es-mx'
import { DonutLargeRounded } from '@mui/icons-material';
import { AuthContext } from '../context/AuthContext';
DayJs.locale('es-mx');
export const ContadorSuVida = ({datosBot}) => {
    const {configuracion} = useContext(AuthContext)

    const mes = DayJs(new Date()).format('MMMM');
    console.log('datos bot',datosBot);

    useEffect(() => {
    }, [datosBot])
    
    
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            backgroundColor:'white',
            borderRadius:10,
            padding:10,
            flex:25,
            justifyContent:'space-around',
            maxWidth:280
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between'
            }}
        >
            <Typography
                style={{
                    fontSize:18
                }}
            >Su vida</Typography>
            <Typography
                style={{
                    fontSize:28,
                    fontWeight:'bold'
                }}
            >{datosBot?.suVida }</Typography>
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center'
                }}
            >
                <FlechaArriba style={{color:'#28C66F',
                        fontSize:18,
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center'
                    }} 
                        />
                <Typography
                    style={{
                        color:'#28C66F',
                        fontSize:16

                    }}
                >{datosBot?.suVida}</Typography>
                <Typography
                    style={{
                        marginLeft:10,
                        color:'#656565',
                        fontSize:12,
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center'
                    }}
                >{mes}</Typography>
            </div>
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                marginLeft:20,
                backgroundColor:configuracion.fondo,
                padding:30,
                borderRadius:15
            }}
        >
            <DonutLargeRounded style={{fontSize:70}} />
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 94 94">
            <g id="Grupo_2389" data-name="Grupo 2389" transform="translate(-614 -176)">
                <rect id="Rectángulo_7" data-name="Rectángulo 7" width="94" height="94" rx="20" transform="translate(614 176)" fill={configuracion.fondo}/>
                <path id="Trazado_47" data-name="Trazado 47" d="M674.41,219.635h9.09A22.539,22.539,0,0,0,663.365,199.5v9.09A13.51,13.51,0,0,1,674.41,219.635Z" transform="translate(0 1)" fill="#642644"/>
                <path id="Trazado_48" data-name="Trazado 48" d="M661.115,235.385a13.491,13.491,0,0,1-2.25-26.795V199.5A22.5,22.5,0,1,0,683.5,224.135h-9.09A13.509,13.509,0,0,1,661.115,235.385Z" transform="translate(0 1)" fill="#642644"/>
            </g>
            </svg> */}
        </div>

    </div>
  )
}
