import React, { useContext } from 'react'
import { TemplatesContext } from '../context/TemplatesContext'
import { Typography } from '@mui/material'

export const TemplateDetalle = () => {
    const {templateSeleccionado} = useContext(TemplatesContext)
  return (
    <div
    style={{
        display:'flex',
        flexDirection:'column',
        margin:20,
       

    }}
>
        <div
            style={{
                backgroundColor:'white',
                padding:20,
                shadowColor: 'blue',
                elevation: 5,  
                whiteSpace:'pre-wrap',
                boxShadow:'1 1 10 1 gray',
                borderRadius:10,
                fontSize:11
            }}
        >
            {templateSeleccionado?.contenido?.body}
        </div>
        {
            templateSeleccionado?.contenido?.items?.map((item, index) => (
                <div
                    style={{fontSize:11, display:'flex', flexDirection:'column', backgroundColor:'white', borderRadius:10, margin:10}}
                >
                    <Typography style={{fontSize:12, fontWeight:'600'}}>
                        {item?.item}
                    </Typography>
                    <Typography style={{fontSize:11, fontWeight:'400'}}>
                        {item?.description}
                    </Typography>
                </div>
            ))
        }
        {
            templateSeleccionado?.contenido?.media ? (
                <div
                    style={{fontSize:11, display:'flex', flexDirection:'column', backgroundColor:'white', borderRadius:10, margin:10}}
                >
                    <Typography style={{fontSize:11, fontWeight:'400'}}>
                    {templateSeleccionado?.contenido?.media?.media[0]}
                    </Typography>
                </div>
            ):null
        }
    </div>
  )
}
